@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto+Mono:wght@400;700&display=swap");
@import url("@tldraw/tldraw/tldraw.css");

body {
  font-family: "Inter";
  margin: 0;
}

.tlui-debug-panel {
  display: none!important;
}